<template>
  <div class="app-item app-content" v-loading="loading" element-loading-text="文件拼命上传中。。。">
      <div class="top-title flex">
        <h4>员工管理</h4>
        <div class="flex">
          <el-button size="small" type="success" @click="exportUser()">导出员工</el-button>
          <el-button type="primary" size="small" @click="dialogFormVisible = true" class="el-btn">添加员工</el-button>
          <el-upload action="" :http-request="fnUploadRequest" :show-file-list="false" ><el-button size="small" type="primary">导入员工</el-button></el-upload>
          <a href="http://laobangjiangtang.oss-cn-shanghai.aliyuncs.com/examples/%E5%91%98%E5%B7%A5%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.xlsx">模板示例</a>
      </div>
      </div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="name" label="员工姓名" width="120" fixed class="el-table-name">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.name"
              placeholder="请输入内容 "
              v-if="showEdit[scope.$index]"
            ></el-input>
            <span v-if="!showEdit[scope.$index]">{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="手机号" width="190">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.mobile"
              placeholder="请输入内容 "
              v-if="showEdit[scope.$index]"
            ></el-input>
            <span v-if="!showEdit[scope.$index]">{{scope.row.mobile}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="积分" width="190">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.integral"
              placeholder="请输入内容 "
              v-if="showEdit[scope.$index]"
            ></el-input>
            <span v-if="!showEdit[scope.$index]">{{scope.row.integral}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="password" label="员工密码" width="190" v-if='pwdShow'>
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.password"
              placeholder="请输入内容 "
              v-if="showEdit[scope.$index]"
            ></el-input>
            <span v-if="!showEdit[scope.$index]">{{scope.row.password}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="sex" label="性别" width="190">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.gender"
              placeholder="请输入内容 "
              v-if="showEdit[scope.$index]"
            ></el-input>
            <span v-if="!showEdit[scope.$index]">{{scope.row.gender}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="职务" width="190">
          <template slot-scope="scope">
            <el-input
              size="small"
              v-model="scope.row.title"
              placeholder="请输入内容 "
              v-if="showEdit[scope.$index]"
            ></el-input>
            <span v-if="!showEdit[scope.$index]">{{scope.row.title}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="department_id" label="所属部门" width="190" >
          <template slot-scope="scope" >
            <el-select placeholder="请选择所属部门" v-model="dept.name"  v-if="showEdit[scope.$index]" multiple collapse-tags>
              <el-option v-for="(item,ind) in dept" :key="ind"  :value="item.id" :label="item.name"></el-option>
            </el-select>
            <div v-if="!showEdit[scope.$index]">
            <span v-for="(item,ind) of scope.row.departments" :key="ind">{{item.name}} </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="updateEmployees(scope.$index, scope.row)">
              <span v-if="showBtn[scope.$index]">保存</span>
              <span v-if="!showBtn[scope.$index]">编辑</span>
            </el-button>
            <!-- <el-button type="text" size="small">停用/启用</el-button> -->
            <el-button type="text" size="small" @click="deleteEmployees(scope.$index,tableData)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Page :total="total" @goTo="goTo"></Page>
      <!-- 添加员工 -->
      <el-dialog title="添加员工" :visible.sync="dialogFormVisible"  class="dialog">
        <el-form label-width="100px" :data="dept">
          <el-form-item label="员工姓名">
            <el-input auto-complete="off" placeholder="请输入员工姓名" v-model="deptname"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input auto-complete="off" placeholder="请输入员工手机号" v-model="deptmobile"></el-input>
          </el-form-item>
          <el-form-item label="积分">
            <el-input auto-complete="off" placeholder="请输入积分" v-model="deptintegral"></el-input>
          </el-form-item>
          <el-form-item label="员工密码">
            <el-input auto-complete="off" placeholder="请输入员工密码" v-model="deptpassword"></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-radio v-model="deptgender" label="1">男</el-radio>
            <el-radio v-model="deptgender" label="2">女</el-radio>
          </el-form-item>
          <el-form-item label="所属部门">
            <el-select placeholder="请选择所属部门" v-model="dept.name" multiple collapse-tags>
              <el-option  v-for="(item,index) in dept" :key="index" :value="item.id" :label="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职务">
            <el-input auto-complete="off" placeholder="请输入员工职务" v-model="depttitle"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogFormVisible = false;addEmployees()">确 定</el-button>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </div>
      </el-dialog>
  </div>
</template>
<script>
import Page from '../../components/Page.vue'
export default {
  components: {
    Page
  },
  data () {
    return {
      userId: null,
      pwdShow: false,
      dialogFormVisible: false,
      deptname: '',
      deptmobile: '',
      deptpassword: '',
      deptgender: '2',
      depttitle: '',
      dept: [],
      deptduties: '',
      manager: '',
      tableData: [],
      // depts: [],
      showEdit: [], // 显示编辑框
      showBtn: [], // 控制按钮 编辑/保存
      total: 0,
      page: 1, // 当前页面
      lastPage: 1, // 最后一页
      deptnames: [],
      gender: '',
      deptintegral: 0,
      loading: false
    }
  },
  methods: {
    // 获取员工列表
    getEmployees (url) {
      this.pwdShow = false
      this.$store.dispatch('getEmployees', url).then(res => {
        console.log('员工：', res)
        this.tableData = res.data
        this.total = res.total

        // 处理页面
        this.page = res.current_page
        // 最后一页
        this.lastPage = res.last_page
      })
    },
    // 翻页
    goTo (page) {
      this.getEmployees(`/api/web/store/users?page=${page}`)
    },
    // turnPage(page){
    //   // 第一页
    //   if (page === 'first') {
    //     if (this.page > 1) {
    //       this.getEmployees('/api/web/store/users?page=1')
    //     }
    //   }
    //   // 最后一页
    //   if (page === 'last') {
    //     if (this.lastPage > this.page) {
    //       this.getEmployees(`/api/web/store/users?page=${this.lastPage}`)
    //     }
    //   }
    //   // 上一页
    //   if (page === 'prev') {
    //     if (this.page > 1) {
    //       this.getEmployees(`/api/web/store/users?page=${this.page - 1}`)
    //     }
    //   }
    //   // 下一页
    //   if (page === 'next') {
    //     if (this.lastPage > this.page) {
    //       this.getEmployees(`/api/web/store/users?page=${this.page + 1}`)
    //     }
    //   }
    // },
    // 增加员工信息
    addEmployees () {
      console.log(this.deptgender)
      if (!/^1[3456789]\d{9}$/.test(this.deptmobile)) {
        this.$message({
          message: '请输入正确的手机号',
          type: 'warning'
        })
        return
      }
      let obj = {
        nick_name: this.deptname,
        integral: this.deptintegral,
        mobile: this.deptmobile,
        password: this.deptpassword,
        gender: this.deptgender,
        department_ids: this.dept.name,
        title: this.depttitle
      }
      // console.log(obj)
      this.$store.dispatch('addEmployees', obj).then(res => {
        // console.log(res)
        if (res.code === 0) {
          this.$message({
            message: '添加成功',
            type: 'success'
          })
          this.getEmployees()
        // location.reload(true)
        } else if (res.code === 10003) {
          this.$message({
            message: res.message,
            type: 'warning'
          })
        }
      })
    },
    // 删除员工信息
    deleteEmployees (index, params) {
      // console.log(index, params)
      // console.log(params[index])
      // params.forEach((ele, ind) => {
      // console.log(ele.id)
      if (this.userId === params[index].id) {
        this.$message({
          message: '管理员账户禁止删除',
          type: 'error'
        })
        return false
      } else {
      // })
        let array = params.splice(index, 1)
        // console.log(array)
        let id = array[0].id
        this.$store.dispatch('deleteEmployees', id).then(res => {
        // console.log(res);
          if (res.code === 0) {
          // this.getDepartments();
            this.$message({
              message: '删除成功',
              type: 'success'
            })
          }
        })
      }
    },
    // 修改员工信息
    updateEmployees (index, row) {
      this.showEdit[index] = !this.showEdit[index]
      this.showBtn[index] = !this.showBtn[index]
      this.pwdShow = true
      if (this.showBtn[index] === false) {
        if (row.gender === '女') {
          this.gender = 2
        } else if (row.gender === '男') {
          this.gender = 1
        }
        // console.log(row.departmentName)
        let obj = {
          id: row.id,
          integral: row.integral,
          nick_name: row.name,
          mobile: row.mobile,
          password: row.password,
          gender: this.gender,
          title: row.title,
          department_ids: this.dept.name
        }
        this.$store.dispatch('updateEmployees', obj).then(res => {
          // console.log(res);
          if (res.code === 0) {
            this.getEmployees()
            this.$message({
              message: '修改成功',
              type: 'success'
            })
          }
        })
      }
    },
    // 上传员工信息文件
    fnUploadRequest (option) {
      // console.log(option.file)
      this.loading = true
      let file = option.file
      let formData = new FormData()
      formData.append('file', file)
      this.$store.dispatch('importUser', formData).then(res => {
        // console.log(res)
        if (res.code === 0) {
          this.$message({
            message: res.message,
            type: 'success'
          })
          this.loading = false
        }
      })
    },
    // 导出员工信息文件
    exportUser () { // 返回数据的格式指定为blob
      this.$store.dispatch('exportUser', { responseType: 'blob' }).then(res => {
        // 创建一个新的 URL 对象
        let url = window.URL.createObjectURL(res)
        // 在页面上生成一个a标签并指定href为上面的url,然后模拟点击，以实现自动下载
        var link = document.createElement('a')
        document.body.appendChild(link)
        link.href = url
        link.download = '员工信息表' + '.xlsx'
        link.click()
      })
    }
  },
  mounted () {
    this.getEmployees()
    this.$store.dispatch('getDepartments').then(res => {
      // console.log(res)
      this.dept = res
      // console.log(this.dept)
      this.$store.dispatch('getInfo').then(res => {
        // console.log(res)
        this.userId = res.info.id
      })
    })
  }
}
</script>
<style lang="less" scoped>
.app-item {
  height: 100%;
  .top-title {
    // display: flex;
    justify-content: space-between;
    // align-items: center;
    .el-button {
      padding: 0 10px;
      height: 40px;
    }
    .el-btn{
      margin:0 10px
    }
    a{
      font-size: 12px;
      margin-top: 22px;
      margin-left:2px;
      color:#409EFF
    }
  }
  .dialog {
    text-align: center;
    .dialog-footer {
      text-align: center;
      margin-top: -10px;
      .el-button {
        padding: 12px 30px;
      }
    }
  }
}
@media screen and (max-width: 414px) {
  .el-table-name{
    width:80px !important
  }
  .top-title{
    h4{
      height:auto;
      line-height: normal
    }
  }
}
</style>
